
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex'
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();

    const store = useStore();

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      store
    };
  }
});
