const DocMenuConfig: object = [

  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
        roles : ["clonallon"]
      },
      {
        sectionTitle: "Consumers",
        route: "/consumers",
        svgIcon: "media/icons/duotone/General/User.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Search Consumers",
            route: "/consumers/list",
            roles : ["clonallon"]
          },
          // {
          //   heading: "Add Consumer",
          //   route: "/consumers/add"
          // }
        ]
      },
      {
        sectionTitle: "Suppliers",
        route: "/suppliers",
        svgIcon: "media/icons/duotone/Communication/Incoming-box.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Search Suppliers",
            route: "/suppliers/list",
            roles : ["clonallon"]
          },
          {
            heading: "Add Supplier",
            route: "/suppliers/add",
            roles : ["clonallon"]
          }
        ]
      },
      {
        sectionTitle: "Products",
        route: "/products",
        svgIcon: "media/icons/duotone/Code/Compiling.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Product Search",
            route: "/products/list",
            roles : ["clonallon"]
          },
          {
            heading: "New Component",
            route: "/products/component/add",
            roles : ["clonallon"]
          },
          {
            heading: "New Composite",
            route: "/products/composite/add",
            roles : ["clonallon"]
          }
        ]
      },

      {
        sectionTitle: "Samples",
        route: "/samples",
        svgIcon: "media/icons/duotone/Code/Compiling.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Sample Search",
            route: "/samples/list",
            roles : ["clonallon"]
          },
          {
            heading: "New Sample",
            route: "/samples/add",
            roles : ["clonallon"]
          }
        ]
      },

      {
        sectionTitle: "Lots",
        route: "/lots",
        svgIcon: "media/icons/duotone/Shopping/Barcode.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Lot Search",
            route: "/lots/list",
            roles : ["clonallon"]
          },
          {
            heading: "Assemble Components",
            route: "/lots/assemble-components",
            roles : ["clonallon"]
          },
          {
            heading: "Invoice Composites",
            route: "/lots/invoice-composites",
            roles : ["clonallon"]
          },
          {
            heading: "Adjustments",
            route: "/lots/adjustments",
            roles : ["clonallon"]
          }
        ]
      },

      {
        sectionTitle: "Orders",
        route: "/orders",
        svgIcon: "media/icons/duotone/Shopping/Cart2.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon"],
        sub: [
          {
            heading: "Supplier Orders",
            route: "/orders/supplier/list",
            roles : ["clonallon"],
          },
          {
            heading: "Consumer Orders",
            route: "/orders/consumer/list",
            roles : ["clonallon"],
          },
        ]
      },
      {
        sectionTitle: "Reports",
        route: "/reports",
        svgIcon: "media/icons/duotone/Files/File.svg",
        fontIcon: "bi-archive",
        roles : ["clonallon", "customer"],
        sub: [
          {
            heading: "Component Stock Levels",
            route: "/reports/component-stock-levels",
            roles : ["clonallon", "customer"],
          },
          {
            heading: "Composite Stock Levels",
            route: "/reports/composite-stock-levels",
            roles : ["clonallon", "customer"],
          },
          // {
          //   heading: "Composite Stock Levels (Customer)",
          //   route: "/reports/composite-stock-levels-customer"
          // },
          {
            heading: "Retrospective Component Stock Levels",
            route: "/reports/retrospective-component-stock-levels",
            roles : ["clonallon", "customer"],
          },
          {
            heading: "Retrospective Composite Stock Levels",
            route: "/reports/retrospective-composite-stock-levels",
            roles : ["clonallon", "customer"],
          },
          // {
          //   heading: "Retrospective Composite Stock Levels (Customer)",
          //   route: "/reports/retrospective-composite-stock-levels-customer"
          // },
          {
            heading: "Lot Shipping History by Lot Number",
            route: "/reports/lot-shipping-history-by-lot-number",
            roles : ["clonallon"],
          },
          {
            heading: "Lot Shipping History by Date",
            route: "/reports/lot-shipping-history-by-date",
            roles : ["clonallon"],
          },
          {
            heading: "Lot Shipping History by Order ID",
            route: "/reports/lot-shipping-history-by-order-id",
            roles : ["clonallon"],
          },
          {
            heading: "Invoices",
            route: "/reports/invoice",
            roles : ["clonallon"],
          }
        ]
      },
    ]
  }
];

export default DocMenuConfig;
