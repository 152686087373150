
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions, Mutations } from "@/store/enums/StoreEnums.ts";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const userName = computed(() => {
      return store.getters.currentUser.first_name+' '+store.getters.currentUser.last_name
    })
    const userInitials = computed(() => {
      return store.getters.currentUser.first_name[0]+store.getters.currentUser.last_name[0]
    })
    const userEmail = computed(() => {
      return store.getters.currentUser.email
    })

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      userName,
      userInitials,
      userEmail,
      signOut
    };
  }
});
