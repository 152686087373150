<template>
  <!--begin::Menu wrapper-->
  <div
    class="header-menu align-items-stretch"
    data-kt-drawer="true"
    data-kt-drawer-name="header-menu"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
    data-kt-place="true"
    data-kt-place-mode="prepend"
    data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
  >
    <!--begin::Menu-->
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
      id="#kt_header_menu"
      data-kt-menu="true"
      v-if="store.getters.currentUser.role.name == 'clonallon'"
    >
      <div class="menu-item me-lg-1">
        <router-link
          to="/products/component/add"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            class="menu-link py-3"
            @click="navigate"
            :class="[isActive && 'active', isExactActive && 'active']"
          >
            <span class="menu-title">New Component</span>
          </a>
        </router-link>
      </div>
      <div class="menu-item me-lg-1">
        <router-link
          to="/products/composite/add"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            class="menu-link py-3"
            @click="navigate"
            :class="[isActive && 'active', isExactActive && 'active']"
          >
            <span class="menu-title">New Composite</span>
          </a>
        </router-link>
      </div>
      <div class="menu-item me-lg-1">
        <router-link
          to="/orders/supplier/add"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            class="menu-link py-3"
            @click="navigate"
            :class="[isActive && 'active', isExactActive && 'active']"
          >
            <span class="menu-title">New Supplier Order</span>
          </a>
        </router-link>
      </div>

      <div class="menu-item me-lg-1">
        <router-link
          to="/lots/assemble-components"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :href="href"
            class="menu-link py-3"
            @click="navigate"
            :class="[isActive && 'active', isExactActive && 'active']"
          >
            <span class="menu-title">Create Assembly</span>
          </a>
        </router-link>
      </div>

      <!--end::Menu-->
    </div>
  </div>
  <!--end::Menu wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex'
import { MenuComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();

    const store = useStore();

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      store
    };
  }
});
</script>
